import React from 'react';
import { useProductPageNodes } from '../hooks/use-product-page-nodes.js'

const RutenBuyButton = ({lang, link}) => {
    const {data} = useProductPageNodes().find(node => node.lang === lang);
    return (
        <a className="ruten-buy-button" href={link || "https://m.ruten.com.tw/"} 
            target="_blank" 
            rel="noopener noreferrer" >
            <img 
                className="ruten-buy-button__img" 
                src={data.ruten_buy_button_image.url} 
                alt={data.ruten_buy_button_image.alt}/>
            <span className="ruten-buy-button__text" >{
                data.ruten_buy_button_text
            }</span>
        </a>
    )
}
export default RutenBuyButton;