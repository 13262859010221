import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout';

import Tag from '../components/tag';
import RutenBuyButton from '../components/ruten-buy-button';
import FileLink from '../components/file-link';
import ProductDisplayer from '../components/product-displayer';
import SEO from '../components/seo';

export default class Product extends React.Component {
  render() {
    const { data, lang } = this.props.data.prismicProducts;
    const { image_gallery } = data;
    const pageData = this.props.data.prismicProductPage.data;
    const category = data.categories.filter(c => c.document);
    return (
      <Layout lang={lang}>
        <SEO
          title={data.product_name.text || data.display_name.text}
          //TODO: Add keywords for products in prsimic
          keywords={[data.product_name.text, ...category.map(({ category }) => category.name)]}
          description={data.product_description.text}
          lang={lang}
        />
        <div id="product-page">
          <main id="product-section" className="responsive-paddings">
            <div id="product-section__grid">
              <div id="image-section">
                <ProductDisplayer imgs={image_gallery.map(({ image }) => image)} />
              </div>
              <div id="descriptive-section">
                <h1 id="product-title">{data.display_name.text}</h1>
                {data.product_name.text && <div id="product-model">{"Model: " + data.product_name.text}</div>}
                <div id="product description" dangerouslySetInnerHTML={{ __html: data.product_description.html }} />
                <div id="panel-size">{data.panel_size && `${pageData.panel_size}: ${data.panel_size}''`}</div>
                <ul id="categories" className="remove-list-styles">
                  {
                    category.map(({ category }) => (
                      <Tag className="category" key={category.document[0].data.name}>{category.document[0].data.name}</Tag>
                    ))
                  }
                </ul>
                <ul id="files" className="remove-list-styles">{
                  data.files.map(({ file_name, file }) => (
                    <li key={file_name}>
                      <FileLink src={file.url} fileName={file_name} />
                    </li>
                  ))
                }</ul>
              </div>
            </div>
            {data.ruten_buy_link && <RutenBuyButton link={data.ruten_buy_link} lang={lang} />}
          </main>
          <section id="table-section" className="responsive-paddings">{
            data.body && data.body
              .filter(slice => slice.__typename === "PrismicProductsBodyTable")
              .map(table =>
                <SliceTable
                  title={table.primary.title.text}
                  rows={table.items}
                  key={table.primary.title.text} />
              )
          }</section>
          <section id="related-items" >

          </section>
        </div>
      </Layout>
    )
  }
}

const SliceTable = ({ title, rows }) => (
  <div className="slice-table">
    <h2 className="slice-table__title">{title}</h2>
    <table className="slice-table__table">
      <tbody className="slice-table__table__body">{
        rows.map(row =>
          <tr className="slice-table__table__row" key={row.row_category}>
            <td className="slice-table__table__row__item slice-table__table__row__item--left">
              {row.row_category}
            </td>
            <td className="slice-table__table__row__item slice-table__table__row__item--right"
              dangerouslySetInnerHTML={{ __html: row.row_item.html }}></td>
          </tr>
        )
      }</tbody>
    </table>
  </div>
)

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    prismicProductPage(lang: {eq: $lang}) {
      data {
        panel_size
        ratio
      }
    }
    prismicProducts(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      lang
      data {
        product_name {
          text
        }
        display_name {
          text
        }
        product_description {
          html
          text
        }
        panel_size
        categories {
          category {
            document {
              data {
                name
              }
            }
          }
        }
        image_gallery {
          image {
            url
            alt
          }
        }
        files {
          file_name
          file {
            url
          }
        }
        ruten_buy_link
        body {
          __typename
					... on PrismicProductsBodyTable {
            primary {
              title {
                text
              }
            }
            items {
              row_item {
                html
              }
              row_category
            }
          }
        }
      }
    }
  }
  
`;