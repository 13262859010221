import React from 'react';
import EnlargerModel from '../components/enlarger-modal';

export default class ProductDisplayer extends React.Component {
    constructor() {
        super();
        this.state = {
            currentImg: 0
        }
    }
    setCurrentImg = (index) => {
        this.setState({ currentImg: index });
    }

    render() {
        const { imgs } = this.props;
        return (
            <div className="product-displayer">
                <EnlargerModel>
                    <img className="product-displayer__current-img"
                        src={imgs[this.state.currentImg].url}
                        alt={imgs[this.state.currentImg].alt} />
                </EnlargerModel>
                {imgs.length > 1 && <ul className="product-displayer__selections remove-list-styles">{
                    imgs.map((img, i) =>
                        <button className={`remove-button-styles product-displayer__selections__item ${this.state.currentImg === i && "active"}`}
                            key={img.alt + i}
                            onClick={() => this.setCurrentImg(i)}>
                            <img className="product-displayer__selections__item__img"
                                src={img.url}
                                alt={img.alt} />
                        </button>)}
                </ul>}
            </div>
        )
    }
}