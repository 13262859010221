import { useStaticQuery, graphql } from 'gatsby';

export const useProductPageNodes = () =>  {
    const { nodes } = useStaticQuery(graphql`
        query {
            allPrismicProductPage {
                nodes {
                    lang
                    data {
                        ruten_buy_button_text
                        ruten_buy_button_image {
                            url
                            alt
                        }
                    }
                }
            }
        }`).allPrismicProductPage;
    return nodes;
}